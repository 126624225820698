import { Trans } from '@lingui/macro'
import Column from 'components/Column'
import { RowBetween } from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import EpochDropdown from 'pages/Leaderboard/EpochDropdown'
import { StyledMore } from 'pages/Liquidity'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import HeaderWrapper from './HeaderWrapper'
import VoteInfo from './VoteInfo'

const StyledHeaderRow = styled(RowBetween)`
  gap: 16px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`

export default function Vote() {
  return (
    <div style={{ position: 'relative' }}>
      <PageWrapper data-testid="vote-page">
        <StyledHeaderRow>
          <Column align="flex-start">
            <TitleRow>
              <ThemedText.LargeHeader>
                <Trans>Vote</Trans>
              </ThemedText.LargeHeader>
            </TitleRow>
            <TitleDesc>
              <ThemedText.HeaderDesc lineHeight="16px">
                <Trans>
                  Earn ROX bribes & trade fees by voting on LP pairs. Select your veROX and allocate votes for any LP
                  pair. Remember to re-cast your votes at the beginning of each new epoch.
                  <StyledMore
                    // className="active-gradient-text"
                    onClick={() => {
                      window.open('https://docs.roguex.io/tutorial/governance/vote')
                    }}
                  >
                      Learn More
                  </StyledMore>
                </Trans>
              </ThemedText.HeaderDesc>
            </TitleDesc>
          </Column>
          <EpochDropdown />
        </StyledHeaderRow>
        {/* <Box mt="20px" width="fit-content">
        <ButtonSecondary height="40px">Add Liquidity</ButtonSecondary>
      </Box> */}
        <VoteInfo />
        <HeaderWrapper />
      </PageWrapper>
    </div>
  )
}
