import { Trans } from '@lingui/macro'
import { useMediaQuery } from '@mui/material'
import Column from 'components/Column'
import { RowBetween } from 'components/Row'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import { StyledMore } from 'pages/Liquidity'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'

import ContentWrapper from './ContentWrapper'
import EpochDropdown from './EpochDropdown'
import Table from './Table'
import TableM from './Table-m'

const StyledHeaderRow = styled(RowBetween)`
  gap: 16px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`

export default function Leaderboard() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  return (
    <PageWrapper data-testid="leaderboard-page">
      <StyledHeaderRow>
        <Column align="flex-start">
          <TitleRow>
            <ThemedText.LargeHeader>
              <Trans>Trade 2 Earn</Trans>
            </ThemedText.LargeHeader>
          </TitleRow>
          <TitleDesc width="auto">
            <ThemedText.HeaderDesc lineHeight="16px">
              <Trans>
                Earn additional rewards from token issuers and partner protocols through trading.
                <StyledMore
                  // className="active-gradient-text"
                  onClick={() => {
                    window.open('https://docs.roguex.io/tutorial/trade/trade2earn')
                  }}
                >
                  
                    Learn More
                </StyledMore>
              </Trans>
            </ThemedText.HeaderDesc>
          </TitleDesc>
        </Column>
        <EpochDropdown />
      </StyledHeaderRow>
      <ContentWrapper />
      {isSmallScreen ? <TableM /> : <Table />}
    </PageWrapper>
  )
}
