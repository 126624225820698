import { Trans } from '@lingui/macro'
import { useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import Column from 'components/Column'
import { PageWrapper } from 'components/swap/styleds'
import { TitleDesc, TitleRow } from 'nft/components/profile/list/shared'
import { StyledMore } from 'pages/Liquidity'
import { BREAKPOINTS, ThemedText } from 'theme'

import LockInfo from './LockInfo'
import LockRewardWrapper from './LockRewardWrapper'
import LockTableList from './LockTableList'
import RebaseAPRWrapper from './RebaseAPRWrapper'

export default function Lock() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)

  return (
    <PageWrapper data-testid="lock-page">
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
      >
        <Column>
          <TitleRow>
            <ThemedText.LargeHeader>
              <Trans>Lock</Trans>
            </ThemedText.LargeHeader>
          </TitleRow>
          <TitleDesc width={isSmallScreen ? '100%' : '400px'}>
            <ThemedText.HeaderDesc lineHeight="16px">
              <Trans>
                Lock ROX for veROX, the governance token of RogueX. The longer the lock period, the more veROX received.
                Vote for LP pairs to receive farming rewards and 40% of fees generated by that voted for pair for the
                remainder of that epoch.
                <StyledMore
                  // className="active-gradient-text"
                  onClick={() => {
                    window.open('https://docs.roguex.io/tutorial/governance/lock')
                  }}
                >
                    Learn More
                </StyledMore>
              </Trans>
            </ThemedText.HeaderDesc>
          </TitleDesc>
        </Column>
        <RebaseAPRWrapper />
      </Box>
      <LockInfo />
      <LockRewardWrapper />
      <LockTableList />
    </PageWrapper>
  )
}
